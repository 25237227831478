import { Fragment, memo } from 'react';
import breastImg from '../../../../assets/images/breast.jpg';
import plusSignBreastImg from '../../../../assets/images/plusSignBreast.png';
import {
  capitalizeFirstLetter,
  styledComponent,
} from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';

const Gyne = ({
  selectedOnExamination,
  setSelectedOnExamination,
  prescriptionItems,
  isDelBtn = true,
}) => {
  let { gyneExamination } = selectedOnExamination || {};
  let { breastExamination } = selectedOnExamination || {};

  const deleteData = (itemName) => {
    switch (itemName) {
      case 'lump':
      case 'nippleArolaComplex':
      case 'axillaryLymphNode':
        breastExamination[itemName] = {
          left: false,
          right: false,
          both: false,
        };
        break;
      case 'lumpPosition':
      case 'nippleArolaComplexPosition':
      case 'axillaryLymphNodePosition':
        breastExamination[itemName] = {
          uoq: false,
          loq: false,
          uiq: false,
          liq: false,
        };
        break;
      case 'isBreastPicture':
      case 'showPlusSignBreast':
        breastExamination[itemName] = false;
        break;
      default:
        breastExamination[itemName] = '';
    }
    selectedOnExamination.breastExamination = breastExamination;
    setSelectedOnExamination({ ...selectedOnExamination });
  };

  const clearData = (itemName) => {
    switch (itemName) {
      case 'fm':
        gyneExamination.fm = '';
        break;
      case 'fhs':
        gyneExamination.fhsPresent = false;
        gyneExamination.fhsAbsent = false;
        break;
      case 'show':
        gyneExamination['showPresent'] = false;
        gyneExamination['showAbsent'] = false;
        break;
      case 'cmt':
        gyneExamination['cmtPresent'] = false;
        gyneExamination['cmtAbsent'] = false;
        break;
      case 'bleeding':
        gyneExamination['bleedingAbsent'] = false;
        gyneExamination['bleedingPresent'] = false;
        break;
      default:
        gyneExamination[itemName] = '';
    }
    selectedOnExamination.gyneExamination = gyneExamination;
    setSelectedOnExamination({ ...selectedOnExamination });
  };

  const getData = (data) => {
    let result = [];
    for (let key in data) {
      if (data[key] === true) {
        result.push(key);
      }
    }
    return result;
  };

  const Title = styledComponent(
    prescriptionItems?.items?.['on-examination']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['on-examination']?.subItemStyle || {},
  );

  return (
    <div className="breast-exam-view">
      {(gyneExamination?.sfh ||
        gyneExamination?.fm ||
        gyneExamination?.fhsPresent ||
        gyneExamination?.fhsAbsent ||
        gyneExamination?.presentation) && (
        <div className="sub-title">
          <b>P/A/E (Pregnency)</b>
        </div>
      )}
      <ul>
        {gyneExamination?.sfh && (
          <li>
            <span className="text-left pr-5">
              <Title>SFH:</Title>
              <Value> {gyneExamination?.sfh}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'sfh'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.fm && (
          <li>
            <span className="text-left pr-5">
              <Title>FM:</Title>
              <Value> {gyneExamination?.fm}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'fm'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {/* {gyneExamination?.fm !== "no" && (
        <div>
          <span className="text-left pr-5">
            FM: {gyneExamination?.fm}
            <Button
              size="sm"
              className="ddbtn-sm-customize"
              variant="outline-secondary"
            >
              <i
                className={`fa ${gyneExamination?.fm ? "fa-plus" : "fa-minus"}`}
              ></i>
            </Button>
          </span>
          <CommonDeleteBtn
            action={clearData}
            itemName={"fm"}
            isDelBtn={isDelBtn}
          />
        </div>
      )} */}
        {gyneExamination?.fhsPresent && (
          <li>
            <span className="text-left pr-5">
              <Title>FHS:</Title>
              <Value> Present</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'fhsPresent'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.fhsAbsent && (
          <li>
            <span className="text-left pr-5">
              <Title>FHS:</Title>
              <Value> Absent</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'fhsAbsent'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.presentation && (
          <li>
            <span className="text-left pr-5">
              <Title>Presentation:</Title>{' '}
              <Value>{gyneExamination?.presentation}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'presentation'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
      </ul>
      {(gyneExamination?.nad ||
        gyneExamination?.uterus ||
        gyneExamination?.wkSize ||
        gyneExamination?.lump) && (
        <div className="sub-title">
          <b>P/A/E (Gyne)</b>
        </div>
      )}
      <ul>
        {gyneExamination?.nad && (
          <li>
            <span className="text-left pr-5">
              <Title>NAD:</Title>
              <Value> Yes</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'nad'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.uterus && (
          <li>
            <span className="text-left pr-5">
              <Title>Uterus:</Title>
              <Value> {gyneExamination?.uterus}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'uterus'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.wkSize && (
          <li>
            <span className="text-left pr-5">
              <Title>wk Size:</Title>
              <Value> {gyneExamination?.wkSize}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'wkSize'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.lump && (
          <li>
            <span className="text-left pr-5">
              <Title>LUMP:</Title>
              <Value> {gyneExamination?.lump}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'lump'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
      </ul>
      {(gyneExamination?.os ||
        gyneExamination?.cervix ||
        gyneExamination?.effacement ||
        gyneExamination?.station ||
        gyneExamination?.mumbrance ||
        gyneExamination?.effacement ||
        gyneExamination?.showPresent ||
        gyneExamination?.showAbsent) && (
        <div className="sub-title">
          <b>P/V/E (Pregnency)</b>
        </div>
      )}
      <ul>
        {gyneExamination?.os && (
          <li>
            <span className="text-left pr-5">
              <Title>OS:</Title>
              <Value> {gyneExamination?.os}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'os'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.cervix && (
          <li>
            <span className="text-left pr-5">
              <Title> Cervix:</Title>
              <Value> {gyneExamination?.cervix}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'cervix'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.effacement && (
          <li>
            <span className="text-left pr-5">
              <Title>Effacement:</Title>
              <Value> {gyneExamination?.effacement}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'effacement'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.station && (
          <li>
            <span className="text-left pr-5">
              <Title>Station:</Title>
              <Value> {gyneExamination?.station}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'station'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.mumbrance && (
          <li>
            <span className="text-left pr-5">
              <Title>Mumbrance:</Title>{' '}
              <Value> {gyneExamination?.mumbrance}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'mumbrance'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.showPresent && (
          <li>
            <span className="text-left pr-5">
              <Title>Show:</Title>
              <Value> Present</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'showPresent'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.showAbsent && (
          <li>
            <span className="text-left pr-5">
              <Title>Show:</Title>
              <Value> Absent</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'showAbsent'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
      </ul>
      {gyneExamination?.inspectionNad && (
        <Fragment>
          <div className="sub-title">
            <b>P/V/E (Gyne)</b>
          </div>
          <ul>
            <li>
              <span className="text-left pr-5">
                <Title>Inspection:</Title>
                <Value> {gyneExamination?.inspectionNad ? 'Yes' : 'No'}</Value>
              </span>
              <CommonDeleteBtn
                action={clearData}
                itemName={'inspectionNad'}
                isDelBtn={isDelBtn}
              />
            </li>
          </ul>
        </Fragment>
      )}
      {gyneExamination?.pseCervix && (
        <Fragment>
          <div className="sub-title">
            <b>Per Speculum Examination (P/S/E)</b>
          </div>
          <ul>
            <li>
              <span className="text-left pr-5">
                <Title>Cervix:</Title>
                <Value> {gyneExamination?.pseCervix}</Value>
              </span>
              <CommonDeleteBtn
                action={clearData}
                itemName={'pseCervix'}
                isDelBtn={isDelBtn}
              />
            </li>
          </ul>
        </Fragment>
      )}

      {(gyneExamination?.bmeUterus ||
        gyneExamination?.bmeWkSize ||
        gyneExamination?.fornix ||
        gyneExamination?.cmtPresent ||
        gyneExamination?.cmtAbsent ||
        gyneExamination?.bleedingAbsent ||
        gyneExamination?.bleedingAbsent) && (
        <div className="sub-title">
          <b>Bimanual Examination (B/M/E)</b>
        </div>
      )}
      <ul>
        {gyneExamination?.bmeUterus && (
          <li>
            <span className="text-left pr-5">
              <Title>Uterus:</Title>
              <Value> {gyneExamination?.bmeUterus}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'bmeUterus'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.bmeWkSize && (
          <li>
            <span className="text-left pr-5">
              <Title>wk Size:</Title>
              <Value> {gyneExamination?.bmeWkSize}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'bmeWkSize'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.fornix && (
          <li>
            <span className="text-left pr-5">
              <Title>Fornix:</Title>
              <Value> {gyneExamination?.fornix}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'fornix'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.cmtPresent && (
          <li>
            <span className="text-left pr-5">
              <Title>CMT:</Title>
              <Value> Present</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'cmtPresent'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.cmtAbsent && (
          <li>
            <span className="text-left pr-5">
              <Title>CMT:</Title>
              <Value> Absent</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'cmtAbsent'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.bleedingAbsent && (
          <li>
            <span className="text-left pr-5">
              <Title>Bleeding:</Title>
              <Value> Present</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'bleedingAbsent'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gyneExamination?.bleedingPresent && (
          <li>
            <span className="text-left pr-5">
              <Title>Bleeding:</Title>
              <Value> Absent</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'bleedingPresent'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {/* Breast area */}
        {getData(breastExamination?.lump).length > 0 && (
          <li>
            <span className="text-left pr-5">
              <Title>LUMP:</Title>{' '}
              <Value>
                {getData(breastExamination?.lump)?.map((item, key) => {
                  return <span key={key}>{capitalizeFirstLetter(item)} </span>;
                })}
              </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'lump'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {getData(breastExamination?.lumpPosition)?.length > 0 && (
          <li>
            <span className="text-left pr-5">
              <Title>Position:</Title>{' '}
              <Value>
                {getData(breastExamination?.lumpPosition)?.map((item, key) => {
                  return (
                    <span key={key}>
                      {`${item.toLocaleUpperCase()}${
                        getData(breastExamination?.lumpPosition).length - 1 !==
                        key
                          ? ', '
                          : ''
                      }`}{' '}
                    </span>
                  );
                })}
              </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'lumpPosition'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.size && (
          <li>
            <span className="text-left pr-5">
              <Title>Size:</Title>{' '}
              <Value>
                {breastExamination?.size +
                  ' ' +
                  breastExamination?.sizeUnit.toLocaleUpperCase()}
              </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'size'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.margin?.length > 0 && (
          <li>
            <Title>Margin:</Title>{' '}
            <Value>
              {breastExamination?.margin?.map((item, key) => {
                return (
                  <span key={key}>
                    {`${item}${
                      breastExamination?.margin.length - 1 === key ? '' : ', '
                    }`}
                  </span>
                );
              })}
            </Value>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'margin'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.consistency?.length > 0 && (
          <li>
            <Title>Consistency:</Title>{' '}
            <Value>
              {breastExamination?.consistency?.map((item, key) => {
                return (
                  <span key={key}>
                    {`${item}${
                      breastExamination?.consistency.length - 1 === key
                        ? ''
                        : ', '
                    }`}
                  </span>
                );
              })}
            </Value>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'consistency'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.localTemperature?.length > 0 && (
          <li>
            <Title>Local Temperature:</Title>{' '}
            <Value>
              {breastExamination?.localTemperature?.map((item, key) => {
                return (
                  <span key={key}>
                    {`${item}${
                      breastExamination?.localTemperature.length - 1 === key
                        ? ''
                        : ', '
                    }`}
                  </span>
                );
              })}
            </Value>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'localTemperature'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.mobility?.length > 0 && (
          <li>
            <Title>Mobility:</Title>{' '}
            <Value>
              {breastExamination?.mobility?.map((item, key) => {
                return (
                  <span key={key}>
                    {`${item}${
                      breastExamination?.mobility.length - 1 === key ? '' : ', '
                    }`}
                  </span>
                );
              })}
            </Value>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'mobility'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.overlyingSkin?.length > 0 && (
          <li>
            <Title>Overlying Skin:</Title>{' '}
            <Value>
              {breastExamination?.overlyingSkin?.map((item, key) => {
                return (
                  <span key={key}>
                    {`${item}${
                      breastExamination?.overlyingSkin.length - 1 === key
                        ? ''
                        : ', '
                    }`}
                  </span>
                );
              })}
            </Value>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'overlyingSkin'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {getData(breastExamination?.nippleArolaComplex)?.length > 0 && (
          <li>
            <span className="text-left pr-5">
              <Title>Nipple Arola Complex:</Title>{' '}
              <Value>
                {getData(breastExamination?.nippleArolaComplex)?.map(
                  (item, key) => {
                    return (
                      <span key={key}>{capitalizeFirstLetter(item)} </span>
                    );
                  },
                )}
              </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'nippleArolaComplex'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {getData(breastExamination?.nippleArolaComplexPosition)?.length > 0 && (
          <li>
            <span className="text-left pr-5">
              <Title>Position:</Title>{' '}
              <Value>
                {getData(breastExamination?.nippleArolaComplexPosition)?.map(
                  (item, key) => {
                    return (
                      <span key={key}>
                        {`${capitalizeFirstLetter(item)}${
                          getData(breastExamination?.nippleArolaComplexPosition)
                            .length -
                            1 !==
                          key
                            ? ', '
                            : ''
                        }`}{' '}
                      </span>
                    );
                  },
                )}
              </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'nippleArolaComplexPosition'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.nippleOthers?.length > 0 && (
          <li>
            <Title>Others:</Title>{' '}
            <Value>
              {breastExamination?.nippleOthers?.map((item, key) => {
                return (
                  <span key={key}>
                    {`${item}${
                      breastExamination?.nippleOthers.length - 1 === key
                        ? ''
                        : ', '
                    }`}
                  </span>
                );
              })}
            </Value>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'nippleOthers'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {getData(breastExamination?.axillaryLymphNode)?.length > 0 && (
          <li>
            <span className="text-left pr-5">
              <Title>Axillary Lymph Node:</Title>{' '}
              <Value>
                {getData(breastExamination?.axillaryLymphNode)?.map(
                  (item, key) => {
                    return (
                      <span key={key}>{capitalizeFirstLetter(item)} </span>
                    );
                  },
                )}
              </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'axillaryLymphNode'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {getData(breastExamination?.axillaryLymphNodePosition)?.length > 0 && (
          <li>
            <span className="text-left pr-5">
              <Title>Position:</Title>{' '}
              <Value>
                {getData(breastExamination?.axillaryLymphNodePosition)?.map(
                  (item, key) => {
                    return (
                      <span key={key}>
                        {`${capitalizeFirstLetter(item)}${
                          getData(breastExamination?.axillaryLymphNodePosition)
                            .length -
                            1 !==
                          key
                            ? ', '
                            : ''
                        }`}{' '}
                      </span>
                    );
                  },
                )}
              </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'axillaryLymphNodePosition'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.number && (
          <li>
            <span className="text-left pr-5">
              <Title>Number:</Title>{' '}
              <Value>
                {breastExamination?.number +
                  ' ' +
                  breastExamination?.numberUnit.toLocaleUpperCase()}
              </Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'number'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.axillaryOthers?.length > 0 && (
          <li>
            <Title>Others:</Title>{' '}
            <Value>
              {breastExamination?.axillaryOthers?.map((item, key) => {
                return (
                  <span key={key}>
                    {`${item}${
                      breastExamination?.axillaryOthers.length - 1 === key
                        ? ''
                        : ', '
                    }`}
                  </span>
                );
              })}
            </Value>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'axillaryOthers'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.note && (
          <li>
            <span className="text-left pr-5">
              <Title>Note:</Title>
              <Value> {breastExamination?.note}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'note'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.findings && (
          <li>
            <span className="text-left pr-5">
              <Title>Findings:</Title>
              <Value> {breastExamination?.findings}</Value>
            </span>
            <CommonDeleteBtn
              action={deleteData}
              itemName={'findings'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.isBreastPicture && (
          <li className="breast">
            <Title>Breast:</Title>
            <img src={breastImg} height={140} width={300} />
            <CommonDeleteBtn
              action={deleteData}
              itemName={'isBreastPicture'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {breastExamination?.showPlusSignBreast && (
          <li className="breast">
            <Title>Breast:</Title>
            <img src={plusSignBreastImg} height={110} width={230} />
            <CommonDeleteBtn
              action={deleteData}
              itemName={'showPlusSignBreast'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

export default memo(Gyne);
