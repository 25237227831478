import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import { useClickAway } from 'react-use';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { useGetRequest } from '../../../hooks/useGetRequest';
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '../../../utils/axiosRequests';
import { handleGroupData } from '../../../utils/helpers';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchAreaAsync';
import TabNav from '../partials/tabNav';
import SearchItemSuggestions from '../searchItemSuggestions';
import AdviceGroupForm from './adviceGroupForm';

const AdviceModal = ({
  modalTitle,
  selectedAdvices,
  setSelectedAdvices,
  selectedAdvicesGroups,
  setSelectedAdvicesGroups,
  showAdvice,
  setShowAdvice,
}) => {
  const [advices, setAdvices] = useState([]);
  const [advicesInSearch, setAdvicesInSearch] = useState([]);
  const [adviceGroups, setAdviceGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdviceGroupModal, setIsAdviceGroupModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [selectedForSub, setSelectedForSub] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const perPage = 25;

  const [selectedIndexForFocus, setSelectedIndexForFocus] = useState(null);
  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    setSelectedIndexForFocus(index);
  };

  const { isLoading: isAdviceLoading, refetch: getAdvices } = useGetRequest(
    'getAdvice',
    `advice?page=${currentPage}&perPage=${perPage}`,
    (data) => {
      if (currentPage > 1) {
        setAdvices([...advices, ...data.data]);
      } else {
        setAdvices(data.data);
        setTotalItem(data.total);
      }
    },
    (e) => {
      console.log(e);
    },
  );

  const selectAdvice = (item) => {
    if (!selectedForSub) {
      if (selectedAdvices.some((advice) => advice.name === item.name)) {
        setSelectedAdvices(
          selectedAdvices.filter((advice) => advice.name !== item.name),
        );
      } else {
        setSelectedAdvices([...selectedAdvices, { name: item.name, note: '' }]);
      }
    } else {
      const parentAdvice = selectedAdvices.find(
        (item) => item.name === selectedForSub,
      );
      let subAdvices = parentAdvice?.subAdvices ? parentAdvice?.subAdvices : [];
      if (subAdvices.length) {
        if (subAdvices.some((subAdvice) => subAdvice.name === item.name)) {
          subAdvices = subAdvices.filter(
            (subAdvice) => subAdvice.name !== item.name,
          );
        } else {
          const { priority, ...rest } = item;
          subAdvices.push(rest);
        }
      } else {
        const { priority, ...rest } = item;
        subAdvices.push(rest);
      }
      parentAdvice.subAdvices = subAdvices;
      setSelectedAdvices([...selectedAdvices]);
    }
  };

  const selectAdviceWithNote = (item) => {
    if (!selectedForSub) {
      if (selectedAdvices.some((advice) => advice.name === item.name)) {
        setSelectedAdvices(
          selectedAdvices.map((advice) =>
            advice.name !== item.name
              ? advice
              : { ...advice, note: item?.note },
          ),
        );
      } else {
        setSelectedAdvices([
          ...selectedAdvices,
          { name: item.name, note: item?.note },
        ]);
      }
    } else {
      const parentAdvice = selectedAdvices.find(
        (item) => item.name === selectedForSub,
      );
      let subAdvices = parentAdvice?.subAdvices ? parentAdvice?.subAdvices : [];
      if (subAdvices.length) {
        if (subAdvices.some((subAdvice) => subAdvice.name === item.name)) {
          subAdvices = subAdvices.filter(
            (subAdvice) => subAdvice.name !== item.name,
          );
        } else {
          const { priority, ...rest } = item;
          subAdvices.push(rest);
        }
      } else {
        const { priority, ...rest } = item;
        subAdvices.push(rest);
      }
      parentAdvice.subAdvices = subAdvices;
      setSelectedAdvices([...selectedAdvices]);
    }
  };

  const handleSearchOrNew = async (selectedOption) => {
    const {
      name: itemName,
      id: existingId,
      customOption: isNewOption,
    } = selectedOption[0];
    if (isNewOption) {
      await postRequest('advice', { name: itemName })
        .then((data) => {
          setAdvices([...advices, data]);
          setSelectedAdvices([
            ...selectedAdvices,
            { id: data.id, name: itemName, note: '' },
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (selectedAdvices?.some((advice) => advice.id === existingId)) {
        let result = selectedAdvices?.filter((item) => item.id !== existingId);
        setSelectedAdvices([...result]);
      } else {
        if (!selectedForSub) {
          setSelectedAdvices([
            ...selectedAdvices,
            { id: existingId, name: itemName, note: '' },
          ]);
        } else {
          const parentAdvice = selectedAdvices?.find(
            (item) => item.id === selectedForSub,
          );
          let subAdvices = parentAdvice?.subAdvices
            ? parentAdvice?.subAdvices
            : [];
          if (subAdvices?.length) {
            if (subAdvices?.some((subAdvice) => subAdvice.id === existingId)) {
              subAdvices = subAdvices?.filter(
                (subAdvice) => subAdvice.id !== existingId,
              );
            } else {
              const { priority, ...rest } = selectedOption[0];
              subAdvices.push(rest);
            }
          } else {
            const { priority, ...rest } = selectedOption[0];
            subAdvices.push(rest);
          }
          parentAdvice.subAdvices = subAdvices;
          setSelectedAdvices([...selectedAdvices]);
        }
      }
    }
    setSearchQuery('');
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setAdvicesInSearch([]);

      const url = `advice?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          setAdvicesInSearch(data?.data);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const generateSubAdvices = () => {
    const subs = selectedAdvices?.map((item) => {
      const subAdvs = item?.subAdvices?.map((sub) => ({
        id: sub.id,
        name: sub.name,
      }));
      return { name: item.name, id: item.id, subAdvices: subAdvs };
    });
    return subs;
  };

  const deleteAdvice = (AdviceId) => {
    deleteRequest(`advice/${AdviceId}`)
      .then((data) => {
        setAdvices(advices.filter((advice) => advice.id !== AdviceId));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteAdviceGroup = (groupId) => {
    deleteRequest(`advice/groups/${groupId}`)
      .then((data) => {
        setAdviceGroups(adviceGroups.filter((group) => group.id !== groupId));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const selectAdviceGroup = (group) => {
    handleGroupData(
      group,
      selectedAdvicesGroups,
      setSelectedAdvicesGroups,
      selectedAdvices,
      setSelectedAdvices,
      'Advice group',
    );
  };

  const removeAdvice = (itemName) => {
    const data = selectedAdvices.filter(
      (selectedItem) => selectedItem.name !== itemName,
    );
    setSelectedAdvices([...data]);
    setSelectedForSub('');
  };

  const removeSubAdvice = (item, subItemName) => {
    let subItems = item?.subAdvices;
    subItems = subItems.filter((subItem) => subItem.name !== subItemName);
    item.subAdvices = subItems;
    const advices = selectedAdvices.map((advice) => {
      if (advice.name === item.name) {
        return item;
      } else {
        return advice;
      }
    });
    setSelectedAdvices([...advices]);
  };

  const handleAdviceNote = (key, val) => {
    selectedAdvices[key].note = val;
    setSelectedAdvices(selectedAdvices);
  };

  const handleAdviceSubNote = (itemMain, parentIndex, key, val) => {
    itemMain.subAdvices[key]['note'] = val;
    selectedAdvices[parentIndex] = itemMain;
    setSelectedAdvices([...selectedAdvices]);
  };

  const handleSelectedForSub = (itemName) => {
    if (itemName === selectedForSub) {
      setSelectedForSub('');
    } else {
      setSelectedForSub(itemName);
    }
  };

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getAdviceSuggestions',
      `prescriptions/suggestions?suggestion_type=advice`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isAdviceGroup, refetch: getAdviceGroups } = useGetRequest(
    'getAdviceGroup',
    `advice/groups`,
    (data) => {
      setAdviceGroups(data);
    },
    (e) => {
      console.log(e);
    },
  );

  useEffect(() => {
    getAdviceGroups();
    getSuggestions();

    if (!showAdvice) {
      setSearchQuery('');
      setAdvicesInSearch([]);
    }
  }, [showAdvice]);

  useEffect(() => {
    getAdvices();
  }, [currentPage]);

  const selectedSubAdviceList = (itemMain, parentIndex) =>
    itemMain?.subAdvices?.map((item, index) => {
      return (
        <Row
          className="selected-item-row"
          key={index}
          style={{ marginLeft: '20px', marginRight: '20px' }}
        >
          <Col lg={6} md={6} sm={6} xs={6}>
            {item.name}
          </Col>
          <Col lg={5} md={5} sm={5} xs={5}>
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item.note}
              placeholder="Enter note"
              onChange={(e) =>
                handleAdviceSubNote(
                  itemMain,
                  parentIndex,
                  index,
                  e.target.value,
                )
              }
            />
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className="text-right">
            <i
              className="fa fa-times-circle"
              aria-hidden="true"
              onClick={() => removeSubAdvice(itemMain, item.name)}
            ></i>
          </Col>
        </Row>
      );
    });

  const SingleAdvice = ({
    item,
    index,
    handleAdviceNote,
    removeAdvice,
    selectedSubAdviceList,
    inputRefs,
    handleFocus,
    selectedIndexForFocus,
  }) => {
    const [note, setNote] = useState('');
    const debouncedNote = useDebouncedValue(note, 300);
    const componentRef = useRef(null);

    useEffect(() => {
      if (debouncedNote) {
        handleAdviceNote(index, debouncedNote);
      }
    }, [debouncedNote]);

    useEffect(() => {
      inputRefs.current[selectedIndexForFocus]?.focus();
    }, []);

    useClickAway(componentRef, () => {
      handleFocus(-1);
    });

    return (
      <Fragment key={index}>
        {item?.title && (
          <span className="advice-title" key={index}>
            {item?.title}:{' '}
          </span>
        )}
        <Row className="selected-item-row" key={index} ref={componentRef}>
          <Col lg={5} md={5} sm={5} xs={5}>
            {item.name}
          </Col>
          <Col lg={5} md={5} sm={5} xs={5}>
            <Form.Control
              ref={(el) => (inputRefs.current[index] = el)}
              size="sm"
              type="text"
              defaultValue={item.note}
              placeholder="Enter note"
              onChange={(e) => setNote(e.target.value)}
              onClick={(e) => handleFocus(index)}
            />
          </Col>
          <Col lg={1} md={1} sm={1} xs={1}>
            <span
              className="text-left"
              style={{
                width: '70%',
                display: 'inline-block',
                cursor: 'pointer',
              }}
            >
              <i
                className={`fa fa-plus ${
                  selectedForSub === item.name ? 'dark' : 'grey'
                }`}
                aria-hidden="true"
                onClick={() => handleSelectedForSub(item.name)}
              ></i>
            </span>
          </Col>
          <Col lg={1} md={1} sm={1} xs={1}>
            <span
              className="text-right"
              style={{ width: '30%', display: 'inline-block' }}
            >
              <i
                className="fa fa-times-circle"
                aria-hidden="true"
                onClick={() => removeAdvice(item.name)}
              ></i>
            </span>
          </Col>
        </Row>
        {selectedSubAdviceList(item, index)}
      </Fragment>
    );
  };

  const selectedAdviceList = () => {
    return selectedAdvices.map((item, index) => {
      return (
        <SingleAdvice
          key={index}
          item={item}
          index={index}
          handleAdviceNote={handleAdviceNote}
          removeAdvice={removeAdvice}
          selectedSubAdviceList={selectedSubAdviceList}
          inputRefs={inputRefs}
          handleFocus={handleFocus}
          selectedIndexForFocus={selectedIndexForFocus}
        />
      );
    });
  };

  let allAdvices = () => {
    return advices.map((item, index) => {
      const isSelected = selectedAdvices.some((data) => {
        if (
          data.name === item.name ||
          data?.subAdvices?.some((sub) => sub.name === item.name)
        ) {
          return true;
        } else {
          return false;
        }
      });

      return (
        <ItemWithDeleteIcon
          key={item.id}
          item={item}
          isSelected={isSelected}
          itemClickAction={selectAdvice}
          removeClickAction={deleteAdvice}
          itemType={`advice`}
          selectItemWithNote={selectAdviceWithNote}
          suggestions={suggestions}
        />
      );
    });
  };

  const allAdviceGroup = () => {
    return adviceGroups.map((group, index) => {
      let isSelected = selectedAdvicesGroups.some(
        (data) => data.id === group.id,
      );
      return (
        <GroupWithDeleteIcon
          key={index}
          item={group}
          isSelected={isSelected}
          itemClickAction={selectAdviceGroup}
          removeClickAction={deleteAdviceGroup}
        />
      );
    });
  };

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = advices.find(
      (advicePlanItem) => advicePlanItem.name === itemName,
    );
    item['note'] = note;
    if (selectedAdvices.some((advice) => advice.name === item.name)) {
      setSelectedAdvices(
        selectedAdvices.map((advice) =>
          advice.name !== item.name ? advice : { ...advice, note: item?.note },
        ),
      );
    } else {
      setSelectedAdvices([
        ...selectedAdvices,
        { name: item.name, note: item?.note },
      ]);
    }
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };

  const formatOptionLabel = ({ value, label }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <span style={{ paddingTop: '4px' }}>{label}</span>
      <span style={{ paddingTop: '3px' }}>
        <i
          className="fa fa-angle-right"
          style={{
            color: '#00b38c',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '18px',
            borderLeft: '1px solid #d9d9d9',
          }}
          onClick={(e) => handleClickOnRightArrow(e, label)}
        />
      </span>
    </div>
  );
  //============End search suggestions ======================//

  return (
    <Modal
      show={showAdvice}
      size="lg"
      className="customize-modal-size"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* <ModalHeader title={modalTitle} action={setShowAdvice} /> */}
      <Modal.Body>
        <SearchArea
          modalTitle={modalTitle}
          closeAction={setShowAdvice}
          setSearchOptions={setAdvicesInSearch}
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          options={advicesInSearch}
          placeholder={'advice'}
          selectedInputs={selectedAdvices}
          handleClickOnRightArrow={handleClickOnRightArrow}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav
            action={setIsAdviceGroupModal}
            selectedItems={selectedAdvices}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">{allAdvices()}</Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0  mt-1">
                {allAdviceGroup()}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <AdviceGroupForm
          modalTitle={modalTitle}
          itemNames={selectedAdvices.map((item) => item.name)}
          advices={selectedAdvices}
          subAdvices={generateSubAdvices()}
          adviceGroups={adviceGroups}
          setAdviceGroups={setAdviceGroups}
          selectedAdvicesGroups={selectedAdvicesGroups}
          setSelectedAdvicesGroups={setSelectedAdvicesGroups}
          isAdviceGroupModal={isAdviceGroupModal}
          setIsAdviceGroupModal={setIsAdviceGroupModal}
        />

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">{selectedAdviceList()}</div>

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};

export default memo(AdviceModal);
