import React, { memo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const OthersPatientInfo = ({
  patientInfo,
  setPatientInfo,
  appointmentInfo,
  setAppointmentInfo,
}) => {
  const [errors, setErrors] = useState({});

  const getErrorMessage = (inputName) => {
    return errors[inputName] ? errors[inputName] : '';
  };

  return (
    <div className="patient-info">
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label className="label-custom">NID</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter NID"
              defaultValue={patientInfo?.patientDetails?.nid}
              onChange={(e) =>
                setPatientInfo({
                  ...patientInfo,
                  patientDetails: {
                    ...patientInfo.patientDetails,
                    nid: e.target.value,
                  },
                })
              }
            />
            <small className="v-error">{getErrorMessage('name')}</small>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label className="d-block label-custom">
              Reference By
            </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Reference By"
              defaultValue={appointmentInfo?.otherDetails?.reference_by}
              onChange={(e) =>
                setAppointmentInfo({
                  ...appointmentInfo,
                  otherDetails: {
                    ...appointmentInfo?.otherDetails,
                    reference_by: e.target.value,
                  },
                })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label className="label-custom">Registration No</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter Registration No"
              defaultValue={patientInfo?.patientDetails?.registration_no}
              onChange={(e) =>
                setPatientInfo({
                  ...patientInfo,
                  patientDetails: {
                    ...patientInfo.patientDetails,
                    registration_no: e.target.value,
                  },
                })
              }
            />
            <small className="v-error">
              {getErrorMessage('registration_no')}
            </small>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label className="d-block label-custom">Disease</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Disease"
              defaultValue={appointmentInfo?.otherDetails?.disease}
              onChange={(e) =>
                setAppointmentInfo({
                  ...appointmentInfo,
                  otherDetails: {
                    ...appointmentInfo?.otherDetails,
                    disease: e.target.value,
                  },
                })
              }
            />
            <small className="v-error">{getErrorMessage('disease')}</small>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label className="label-custom">Date of Admission</Form.Label>
            <DatePicker
              selected={
                appointmentInfo?.otherDetails?.date_of_admission
                  ? new Date(appointmentInfo?.otherDetails?.date_of_admission)
                  : null
              }
              dateFormat="dd/MM/yyyy"
              className="form-control form-control-sm"
              placeholderText="dd/mm/yyyy"
              popperPlacement="bottom-start"
              onChange={(date) =>
                setAppointmentInfo({
                  ...appointmentInfo,
                  otherDetails: {
                    ...appointmentInfo?.otherDetails,
                    date_of_admission: date,
                  },
                })
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label className="d-block label-custom">
              Date of Discharge
            </Form.Label>
            <DatePicker
              selected={
                appointmentInfo?.otherDetails?.date_of_discharge
                  ? new Date(appointmentInfo?.otherDetails?.date_of_discharge)
                  : null
              }
              dateFormat="dd/MM/yyyy"
              className="form-control form-control-sm"
              placeholderText="dd/mm/yyyy"
              popperPlacement="bottom-start"
              onChange={(date) =>
                setAppointmentInfo({
                  ...appointmentInfo,
                  otherDetails: {
                    ...appointmentInfo?.otherDetails,
                    date_of_discharge: date,
                  },
                })
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default memo(OthersPatientInfo);
