import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import RxNameType from '../partials/rx/rxNameType';
import SelectMedicineCustom from './selectMedicineCustom';
import { useClickAway } from 'react-use';

const MedicineSearchAreaAsync = ({
  setShowRx,
  setShowMedicineName,
  handleOnInputChange,
  onChange,
  options,
  searchQuery,
  setSearchQuery,
  selectedInputs,
  showMedicineName,
  setIsMedicineModal,
  setMedicinesInSearch,
  handleClickOnRightArrow,
}) => {

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    if (options?.length > 0) {
      setTimeout(() => {
        setMedicinesInSearch([]);
        setSearchQuery('');
      }, 0);
    }
  })

  console.log(options, 'options');
  
  return (
    <Row style={{ alignItems: 'center' }}>
      <Col md={2}>
        <span className="modal-title">Rx</span>
      </Col>
      <Col
        md={7}
        style={{ position: 'relative', marginLeft: '12px' }}
        className="search-parent"
        ref={ref}
      >
        <SelectMedicineCustom
          handleInputChange={handleOnInputChange}
          onChange={onChange}
          options={options}
          searchQuery={searchQuery}
          showMedicineName={showMedicineName}
          selectedInputs={selectedInputs}
          handleClickOnRightArrow={handleClickOnRightArrow}
        />
        <svg width="16" height="16" className="search-icon" viewBox="0 0 20 20">
          <path
            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
            stroke="currentColor"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        <span className="new-rx-btn" onClick={() => setIsMedicineModal(true)}>
          <i className="fa fa-plus-circle" aria-hidden="true"></i> Create
        </span>
      </Col>
      <Col md={2} className="pl-0">
        <RxNameType
          setShowRx={setShowRx}
          showMedicineName={showMedicineName}
          setShowMedicineName={setShowMedicineName}
        />
      </Col>
    </Row>
  );
};

export default MedicineSearchAreaAsync;
