import React, { Fragment, memo, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { countOccurrencesOf } from '../../../utils/helpers';

const SelectMedicineCustom = ({
  handleInputChange,
  options,
  onChange,
  searchQuery,
  selectedInputs,
  showMedicineName,
  handleClickOnRightArrow,
}) => {
  const typeaheadRef = useRef(null);
  const handleOrgSearch = () => {};

  const handleSelection = (selected) => {
    onChange(selected);
    if (typeaheadRef.current) {
      typeaheadRef.current.clear();
      typeaheadRef.current.focus();
      typeaheadRef.current.toggleMenu();
    }
  };

  const initialState = {
    disabled: false,
    dropup: false,
    flip: false,
    highlightOnlyResult: false,
    minLength: 0,
    open: options?.length > 1 || searchQuery ? true : false,
  };

  return (
    <Fragment>
      <Typeahead
        id="search-by-item"
        {...initialState}
        labelKey="brandName"
        allowNew
        minLength={1}
        onSearch={handleOrgSearch}
        options={options}
        newSelectionPrefix="Click to add new: "
        ref={typeaheadRef}
        placeholder={'Search / Add medicine'}
        onInputChange={handleInputChange}
        onChange={handleSelection}
        renderMenuItemChildren={(option, props) => {
          const isSelected = selectedInputs?.some(
            (selected) => selected.id === option.id,
          );

          return (
            <Row key={option?.id} className={isSelected ? 'selected-opt' : ''}>
              <Col
                style={{
                  marginBottom: '2px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                md={7}
                className="pr-0"
                title={option?.strength}
              >
                <div>
                  <input
                    type="checkbox"
                    style={{ marginRight: '12px', cursor: 'pointer' }}
                    checked={isSelected}
                  />
                </div>
                <div>
                  {/* <div style={{ marginBottom: '2px' }}>
                    {showMedicineName === 'genericName' ? (
                      <Fragment>
                        <span className="medicine-name">
                          {option?.genericName}
                        </span>{' '}
                        <span
                          style={{
                            fontWeight: 'normal',
                            fontSize: '12px',
                            color: '#bf620b',
                          }}
                        >
                          {option?.strength &&
                          countOccurrencesOf(option?.strength, '+') <= 5
                            ? ` - ${option?.strength} `
                            : ''}
                        </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span className="medicine-name">
                          {option?.brandName}
                        </span>{' '}
                        <span
                          style={{
                            fontWeight: 'normal',
                            fontSize: '12px',
                            color: '#bf620b',
                          }}
                        >
                          {option?.strength &&
                          countOccurrencesOf(option?.strength, '+') <= 5
                            ? ` - ${option?.strength} `
                            : ''}
                        </span>
                      </Fragment>
                    )}
                  </div> */}
                  <div style={{ marginBottom: '2px' }}>
                    <span className="medicine-name">
                      {showMedicineName === 'genericName'
                        ? option?.genericName
                        : option?.brandName}
                    </span>{' '}
                    <span>
                      {option?.strength &&
                        countOccurrencesOf(option?.strength, '+') <= 1 && (
                          <span
                            style={{
                              fontWeight: 'normal',
                              fontSize: '12px',
                              color: '#bf620b',
                            }}
                          >
                            {` - ${option?.strength}`}
                          </span>
                        )}
                    </span>
                  </div>
                  <div
                    className="medicine-company"
                    style={{ fontSize: '10px' }}
                  >
                    <span>{option?.companyName}</span>
                  </div>
                </div>
              </Col>
              {/* <div>
                <span
                  className="medicine-type"
                  style={{
                    color: 'green',
                    marginRight: '8px',
                    textAlign: 'left',
                  }}
                >
                  {option?.type}
                </span>
              </div> */}
              <Col md={3} className="pl-0 pr-0">
                <span
                  className="medicine-type"
                  style={{
                    color: 'green',
                    textAlign: 'end',
                  }}
                >
                  {option?.type}
                </span>
              </Col>
              <Col md={1} className="pr-0" style={{ paddingTop: '3px' }}>
                <i
                  className="fa fa-angle-right"
                  onClick={(e) => handleClickOnRightArrow(e, option)}
                />
              </Col>
            </Row>
          );
        }}
        clearButton
      />

      <span className="searchbar-expand-sign">
        <svg
          height="23"
          width="22"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          class="expand-icon"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </span>
    </Fragment>
  );
};

export default memo(SelectMedicineCustom);
