import { memo } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { checkDoctorDept } from '../../../utils/helpers';

const OnExaminationTabNav = ({ setSelectedOnExaminationTab, specialties }) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const doctorSpeciality = userInfo?.speciality[0];

  const isSystemic = () => {
    return (
      checkDoctorDept('cardiology', doctorSpeciality, specialties) ||
      checkDoctorDept('cardiovascular', doctorSpeciality, specialties) ||
      checkDoctorDept('vascular surgery', doctorSpeciality, specialties) ||
      checkDoctorDept('cardiothoracic surgery', doctorSpeciality, specialties)
    );
  };

  const isBreast = () => {
    return (
      checkDoctorDept('gynecology', doctorSpeciality, specialties) ||
      checkDoctorDept('surgery', doctorSpeciality, specialties) ||
      checkDoctorDept('plastic surgery', doctorSpeciality, specialties)
    );
  };

  return (
    <Nav variant="pills" className="custom-tab gyne-tab">
      <Row style={{ display: 'contents' }} className="pr-0 pl-0">
        <Col className="pl-0 pr-0">
          <Nav.Item>
            <Nav.Link
              eventKey="observation"
              onClick={() => setSelectedOnExaminationTab('observation')}
            >
              {userInfo?.email === 'israt@gmail.com'
                ? 'MSK Examination'
                : 'Observation'}
            </Nav.Link>
          </Nav.Item>
        </Col>
        <Col className="pl-0 pr-0">
          <Nav.Item>
            <Nav.Link
              eventKey="generalExamination"
              onClick={() => setSelectedOnExaminationTab('generalExamination')}
            >
              General Examination
            </Nav.Link>
          </Nav.Item>
        </Col>
        {checkDoctorDept('opthalmoloy', doctorSpeciality, specialties) && (
          <Col className="pl-0 pr-0">
            <Nav.Item>
              <Nav.Link
                eventKey="ocularExamination"
                onClick={() => setSelectedOnExaminationTab('ocularExamination')}
              >
                Ocular Examination
              </Nav.Link>
            </Nav.Item>
          </Col>
        )}
        {isBreast() && (
          <>
            {checkDoctorDept('gynecology', doctorSpeciality, specialties) && (
              <Col className="pl-0 pr-0">
                <Nav.Item>
                  <Nav.Link
                    eventKey="gyneExamination"
                    onClick={() =>
                      setSelectedOnExaminationTab('gyneExamination')
                    }
                  >
                    Gyne Examination
                  </Nav.Link>
                </Nav.Item>
              </Col>
            )}
            <Col className="pl-0 pr-0">
              <Nav.Item>
                <Nav.Link
                  eventKey="breastExamination"
                  onClick={() =>
                    setSelectedOnExaminationTab('breastExamination')
                  }
                >
                  Breast Examination
                </Nav.Link>
              </Nav.Item>
            </Col>
          </>
        )}
        {checkDoctorDept('dental', doctorSpeciality, specialties) && (
          <Col className="pl-0 pr-0">
            <Nav.Item>
              <Nav.Link
                eventKey="dentalExamination"
                onClick={() => setSelectedOnExaminationTab('dentalExamination')}
              >
                Dental Examination
              </Nav.Link>
            </Nav.Item>
          </Col>
        )}
        {isSystemic() && (
          <Col className="pl-0 pr-0">
            <Nav.Item>
              <Nav.Link
                eventKey="systemicExamination"
                onClick={() =>
                  setSelectedOnExaminationTab('systemicExamination')
                }
              >
                Systemic Examination
              </Nav.Link>
            </Nav.Item>
          </Col>
        )}
        {['oncology', 'hematology'].some((dept) =>
          checkDoctorDept(dept, doctorSpeciality, specialties),
        ) && (
          <Col className="pl-0 pr-0">
            <Nav.Item>
              <Nav.Link
                eventKey="oncologyExamination"
                onClick={() =>
                  setSelectedOnExaminationTab('oncologyExamination')
                }
              >
                {checkDoctorDept('hematology', doctorSpeciality, specialties)
                  ? 'Hematology'
                  : 'Oncology'}{' '}
                Examination
              </Nav.Link>
            </Nav.Item>
          </Col>
        )}
        {['otolaryngology', 'otorhinolaryngology'].some((dept) =>
          checkDoctorDept(dept, doctorSpeciality, specialties),
        ) && (
          <Col className="pr-0">
            <Nav.Item>
              <Nav.Link
                eventKey="earNoseThroatExamination"
                onClick={() =>
                  setSelectedOnExaminationTab('earNoseThroatExamination')
                }
              >
                ENT Examination
              </Nav.Link>
            </Nav.Item>
          </Col>
        )}
        {checkDoctorDept('neurology', doctorSpeciality, specialties) && (
          <Col className="pl-0 pr-0">
            <Nav.Item>
              <Nav.Link
                eventKey="neurologyExamination"
                onClick={() =>
                  setSelectedOnExaminationTab('neurologyExamination')
                }
              >
                Nervous System
              </Nav.Link>
            </Nav.Item>
          </Col>
        )}
      </Row>
    </Nav>
  );
};

export default memo(OnExaminationTabNav);
