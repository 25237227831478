import { Fragment, memo } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { checkDoctorDept } from '../../../utils/helpers';

const HistoryTabNav = ({ selectedItems, setSelectedHistoryTab, action }) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const specialties = useSelector(
    (state) => state.specialtyReducer.specialties,
  );
  // let tabArr = [
  //   'medical',
  //   'drug',
  //   'investigation',
  //   'investigation',
  //   'personal',
  //   'family',
  //   'gynocology',
  //   'surgical',
  //   'others',
  // ];
  // const getTab = () => {
  //   return tabArr.map((item) => {
  //     return (
  //       <Col>
  //         <Nav.Item>
  //           <Nav.Link eventKey={item}>{item}</Nav.Link>
  //         </Nav.Item>
  //       </Col>
  //     );
  //   });
  // };

  const isVisible = () => {
    const result = ['gynecology', 'surgery'].some((dept) =>
      checkDoctorDept(dept, userInfo?.speciality[0], specialties),
    );
    return result;
  };

  const isGyne = checkDoctorDept(
    'gynecology',
    userInfo?.speciality[0],
    specialties,
  );

  return (
    <Nav variant="pills" className="custom-tab mt-2">
      {userInfo?.email === 'bnasrin.bsmmu@gmail.com' ? (
        <Row style={{ display: 'contents' }} className="nav-history">
          <Col>
            <Nav.Item>
              <Nav.Link eventKey="gynecology">Gynecology</Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="medical"
                onClick={() => setSelectedHistoryTab('medical')}
              >
                Medical
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="medicalGroup"
                title="Medical Group"
                onClick={() => setSelectedHistoryTab('medicalGroup')}
              >
                M. Group
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="drug"
                onClick={() => setSelectedHistoryTab('drug')}
              >
                Drug
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="investigation"
                onClick={() => setSelectedHistoryTab('investigation')}
              >
                Investigation
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="personal"
                onClick={() => setSelectedHistoryTab('personal')}
              >
                Personal
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="family"
                onClick={() => setSelectedHistoryTab('family')}
              >
                Family
              </Nav.Link>
            </Nav.Item>
          </Col>
          {isVisible() && (
            <>
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="surgical">Surgical</Nav.Link>
                </Nav.Item>
              </Col>
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="others">Others</Nav.Link>
                </Nav.Item>
              </Col>
            </>
          )}
          {selectedItems?.length > 1 && (
            <Col md={1} className="text-right">
              <i
                className="fa fa-save"
                aria-hidden="true"
                onClick={() => action(true)}
              ></i>
            </Col>
          )}
        </Row>
      ) : (
        <Row style={{ display: 'contents' }} className="nav-history">
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="medical"
                onClick={() => setSelectedHistoryTab('medical')}
              >
                Medical
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="medicalGroup"
                title="Medical Group"
                onClick={() => setSelectedHistoryTab('medicalGroup')}
              >
                M. Group
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="drug"
                onClick={() => setSelectedHistoryTab('drug')}
              >
                Drug
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="investigation"
                onClick={() => setSelectedHistoryTab('investigation')}
              >
                Investigation
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="personal"
                onClick={() => setSelectedHistoryTab('personal')}
              >
                Personal
              </Nav.Link>
            </Nav.Item>
          </Col>
          <Col>
            <Nav.Item>
              <Nav.Link
                eventKey="family"
                onClick={() => setSelectedHistoryTab('family')}
              >
                Family
              </Nav.Link>
            </Nav.Item>
          </Col>
          {isVisible() && (
            <Fragment>
              {isGyne && (
                <Col>
                  <Nav.Item>
                    <Nav.Link eventKey="gynecology">Gynecology</Nav.Link>
                  </Nav.Item>
                </Col>
              )}
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="surgical">Surgical</Nav.Link>
                </Nav.Item>
              </Col>
              <Col>
                <Nav.Item>
                  <Nav.Link eventKey="others">Others</Nav.Link>
                </Nav.Item>
              </Col>
            </Fragment>
          )}
          {selectedItems?.length > 1 && (
            <Col md={1} className="text-right">
              <i
                className="fa fa-save"
                aria-hidden="true"
                onClick={() => action(true)}
              ></i>
            </Col>
          )}
        </Row>
      )}
    </Nav>
  );
};

export default memo(HistoryTabNav);
