import { memo } from 'react';
import { Col, Row } from 'react-bootstrap';

const NonSearchHistory = ({ modalTitle, closeAction }) => {
  return (
    <Row>
      <Col md={3} sm={3}>
        <span className="modal-title">{modalTitle}</span>
      </Col>
      <Col
        md={6}
        sm={8}
        style={{ position: 'relative' }}
        className="search-parent"
      ></Col>
      <Col md={3} sm={1} className="text-right">
        <i
          onClick={() => closeAction(false)}
          className="fa fa-times-circle "
          aria-hidden="true"
        ></i>
      </Col>
    </Row>
  );
};

export default memo(NonSearchHistory);
