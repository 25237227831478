import { memo } from 'react';
import { Row } from 'react-bootstrap';
import ItemWithDeleteIcon from '../itemWithDeleteIcon';

const MedicalHistory = ({
  medical,
  selectedData,
  medicalHistories,
  setSelectedHistories,
  suggestions,
}) => {
  const selectMedicalHistory = (item) => {
    if (medical.some((data) => data.name === item.name)) {
      medical = medical.filter((data) => data.name !== item.name);
    } else {
      medical = [
        ...medical,
        {
          name: item.name,
          present: '',
          absent: '',
          unit: 'day(s)',
          duration: '',
          note: '',
        },
      ];
    }
    setSelectedHistories({ ...selectedData, medical });
  };

  const selectMedicalHistoryWithNote = (item) => {
    const isItemExist = medical.some((medicalItem) => {
      if (medicalItem.name === item.name) {
        return true;
      }
    });

    let updatedItems = [];
    if (isItemExist) {
      updatedItems = medical.map((medicalItem) => {
        if (medicalItem.name !== item.name) return medicalItem;
        else return { ...medicalItem, note: item?.note ? item?.note : '' };
      });
    } else {
      updatedItems = [
        ...medical,
        {
          name: item.name,
          present: 'yes',
          absent: '',
          unit: 'day(s)',
          duration: '',
          note: item?.note ? item?.note : '',
        },
      ];
    }
    setSelectedHistories({ ...selectedData, medical: updatedItems });
  };

  const deleteDiagnosis = () => {};

  let list = () => {
    return medicalHistories.map((item, index) => {
      let isSelected = medical?.some((data) => data.name === item.name);
      isSelected = isSelected ? true : false;
      return (
        <ItemWithDeleteIcon
          key={index}
          item={item}
          isSelected={isSelected}
          itemClickAction={selectMedicalHistory}
          removeClickAction={deleteDiagnosis}
          selectItemWithNote={selectMedicalHistoryWithNote}
          suggestions={suggestions}
        />
      );
    });
  };

  return <Row className="complains-area mr-0 ml-0">{list()}</Row>;
};

export default memo(MedicalHistory);
