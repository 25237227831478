import { memo, useState } from 'react';
import { Form } from 'react-bootstrap';

const InvestigationUnit = ({
  index,
  units,
  item,
  selectedInvestigations,
  action,
  type = 'investigation',
}) => {
  const [searchTerm, setSearchTerm] = useState(item?.unit);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filtered = units.filter((item) => item?.name?.includes(value));
    selectedInvestigations[index]['unit'] = value;
    action([...selectedInvestigations]);
    setFilteredOptions(filtered);
  };

  const handleInvestigationUnit = (option) => {
    if (type === 'history') {
      selectedInvestigations[index]['unit'] = option;
      action(selectedInvestigations);
    } else {
      selectedInvestigations[index]['unit'] = option;
      action([...selectedInvestigations]);
    }
    setSearchTerm(option);
    setFilteredOptions([]);
  };

  return (
    <div>
      <Form.Control
        size="sm"
        type="text"
        value={searchTerm}
        defaultValue={item?.unit}
        placeholder="Search/Add unit"
        onChange={handleInputChange}
      />
      {filteredOptions.length > 0 && (
        <div className="inv-unit">
          {filteredOptions?.map((option, key) => (
            <div key={key} onClick={() => handleInvestigationUnit(option.name)}>
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(InvestigationUnit);
