import React, { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  itemSettingStyle,
  MEDICINE_TYPES_SHORTS,
  countOccurrencesOf,
  getDragDropStyle,
  organizeParentChild,
  checkDoctorDept,
} from '../../../utils/helpers';
import EditSelectedMedicineItem from './editSelectedMedicineItem';
import SelectedMedicineSchedule from './selectedMedicineSchedule';
import CommonDeleteBtn from './commonDeleteBtn';

const RxView = ({
  selectedMedicines,
  setSelectedMedicines,
  instructions,
  setInstructions,
  setShowRx,
  prescriptionItems,
  editMedicineId,
  setEditMedicineId,
  element,
  type,
  isHistoryPage = false,
}) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const specialties = useSelector(
    (state) => state.specialtyReducer.specialties,
  );
  const isGynecology = checkDoctorDept(
    'gynecology',
    userInfo?.speciality[0],
    specialties,
  );
  const isActivePatientOption =
    isGynecology && userInfo?.doctorDetails?.isGyneRxView;

  const handleSelectedMedicine = (
    fieldName,
    medicineId,
    value,
    itemDetailIndex,
  ) => {
    const objIndex = selectedMedicines.findIndex(
      (medicine) => medicine.id == medicineId,
    );

    switch (fieldName) {
      case 'quantities':
      case 'quantitiesField':
      case 'quantityUnit':
      //case 'duration':
      //case 'durationUnit':
      case 'quantity':
      case 'schedule':
      case 'scheduleUnit':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        setSelectedMedicines([...selectedMedicines]);
        break;
      case 'duration':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        if (value === '') {
          selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][
            'durationUnit'
          ] = '';
        }
        setSelectedMedicines([...selectedMedicines]);
        break;
      case 'durationUnit':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        if (value === '' || value === 'চলবে') {
          selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][
            'duration'
          ] = '';
        }
        setSelectedMedicines([...selectedMedicines]);
        break;
      case 'quantitiesData':
        selectedMedicines[objIndex] = value;
        setSelectedMedicines([...selectedMedicines]);
        break;
      case 'instructions':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          [
            ...new Set([
              ...selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][
                fieldName
              ],
              value,
            ]),
          ];
        setSelectedMedicines([...selectedMedicines]);
        break;
      case 'instructionRemove':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][
          'instructions'
        ] = value;
        setSelectedMedicines([...selectedMedicines]);
        break;
      case 'itemDetails':
        selectedMedicines[objIndex]['itemDetails'] = value;
        setSelectedMedicines([...selectedMedicines]);
        break;
      case 'similarMedicine':
        let replacedData = selectedMedicines[objIndex];
        replacedData.name = value?.brandName;
        replacedData.id = value?.id;
        replacedData.brandName = value?.brandName;
        replacedData.companyName = value?.companyName;
        replacedData.genericName = value?.genericName;
        replacedData.strength = value?.strength;
        replacedData.type = value?.type;
        replacedData.altName = value?.altName;
        replacedData.createdBy = value?.createdBy;
        selectedMedicines[objIndex] = replacedData;
        setSelectedMedicines([...selectedMedicines]);
        break;
      case 'medicineType':
        selectedMedicines[objIndex]['type'] = value;
        setSelectedMedicines([...selectedMedicines]);
        break;
    }
  };

  const medicineUpdated = (updatedData, index) => {
    selectedMedicines[index] = updatedData;
    setSelectedMedicines([...selectedMedicines]);
  };

  const deleteMedicine = (itemName, medicineId) => {
    const result = selectedMedicines.filter(
      (medicine) => medicine.id !== medicineId,
    );
    setSelectedMedicines([...result]);
  };

  const selectedMedicineRow = (items) =>
    items.map((item, index) => {
      let objIndex = selectedMedicines.findIndex(
        (medicine) => medicine.id == item.id,
      );
      return (
        <EditSelectedMedicineItem
          item={item}
          itemIndexNo={objIndex}
          key={index}
          medicineUpdated={medicineUpdated}
          showMedicineName={true}
          instructions={instructions}
          setInstructions={setInstructions}
          handleSelectedMedicine={handleSelectedMedicine}
          setEditMedicineId={setEditMedicineId}
        />
      );
    });

  let styles = {
    listStyle: 'none',
  };
  if (type !== 'print') {
    styles.minHeight = '200px';
  }

  const getCountSchedule = (item) => {
    const str =
      item.quantity +
      ' ' +
      item.quantityUnit +
      ' ' +
      item.schedule +
      ' ' +
      item.scheduleUnit;

    return str.length;
  };

  const getCountInstructions = (item) => {
    const str = item?.instructions?.map((insItem, key) => (
      <span key={key}>
        {insItem} {item.instructions.length !== key + 1 && ', '}
      </span>
    ));
    return str.join(',').length;
  };

  const getRxStrength = (item) => {
    let strengthGeneric = '';
    if (element?.showGeneric) {
      strengthGeneric =
        item?.strength && countOccurrencesOf(item.strength, '+') <= 1
          ? ' ' + item?.strength + ' '
          : '';
    } else {
      strengthGeneric =
        item.strength && countOccurrencesOf(item.strength, '+') <= 1
          ? ' (' + item.strength + ')'
          : '';
    }
    return strengthGeneric;
  };

  const getRxGenericName = (item) => {
    if (element?.showGeneric) {
      return (
        <span style={{ color: 'grey', fontStyle: 'italic' }}>
          ({`${item?.genericName}`})
        </span>
      );
    } else {
      return '';
    }
  };

  const rxWhite = element.lineDraw ? 'rx-white' : '';
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      selectedMedicines,
      result.source.index,
      result.destination.index,
    );
    setSelectedMedicines(reorderedItems);
  };

  // this function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <div
      className={`row item-row selected-med-view ${
        selectedMedicines.length ? 'pb-2' : ''
      }`}
      key={`rx`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span
        className="prescription-item"
        {...(!isHistoryPage
          ? {
              onClick: (e) => {
                e.stopPropagation();
                setShowRx(true);
              },
            }
          : {})}
      >
        {element?.alterName?.length ? (
          <span>{element?.alterName} +</span>
        ) : (
          <span>Rx +</span>
        )}
      </span>
      {isActivePatientOption ? (
        <Fragment>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-wife">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="droppable-wife"
                >
                  {selectedMedicines?.filter(
                    (item) => item.gynePatType === 'wife',
                  )?.length > 0 && <b>For Wife:</b>}
                  {organizeParentChild(selectedMedicines)
                    ?.filter((item) => item.gynePatType === 'wife')
                    .map((item, index) => (
                      <Fragment>
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Row
                              className="selected-medicine-row"
                              key={item.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getDragDropStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <Col
                                className={`medicine-schedule-parent `}
                                {...(!isHistoryPage
                                  ? {
                                      onClick: (e) =>
                                        setEditMedicineId(item?.id),
                                    }
                                  : {})}
                              >
                                <div
                                  className={`text-left ${
                                    item.parentId ? 'child-medicine' : ''
                                  }`}
                                >
                                  <span
                                    className={
                                      element?.showGeneric ? 'bold' : ''
                                    }
                                    style={itemSettingStyle(
                                      prescriptionItems?.items?.rx?.itemStyle ||
                                        {},
                                    )}
                                  >
                                    {item?.parentId ? item.sl : index + 1}.{' '}
                                    {MEDICINE_TYPES_SHORTS[item.type]}{' '}
                                    {item.name} {`${getRxStrength(item)}`}{' '}
                                    {getRxGenericName(item)}{' '}
                                    {item?.alterMedicine
                                      ? ` /  ${
                                          MEDICINE_TYPES_SHORTS[
                                            item?.alterMedicine?.type
                                          ]
                                        } ${
                                          item?.alterMedicine?.name
                                        } ${' '} ${getRxStrength(
                                          item?.alterMedicine,
                                        )}`
                                      : ''}{' '}
                                  </span>
                                </div>
                                {item?.itemDetails?.map((data, index) => {
                                  return (
                                    <SelectedMedicineSchedule
                                      data={data}
                                      item={item}
                                      typeName={item?.type}
                                      element={element}
                                      rxWhite={rxWhite}
                                      prescriptionItems={prescriptionItems}
                                      setEditMedicineId={setEditMedicineId}
                                    />
                                  );
                                })}
                              </Col>
                              <Col md={1}>
                                {!isHistoryPage && (
                                  <CommonDeleteBtn
                                    action={deleteMedicine}
                                    itemName={'rx'}
                                    delId={item.id}
                                  />
                                )}
                              </Col>
                              {editMedicineId === item.id &&
                                !isHistoryPage &&
                                selectedMedicineRow(
                                  selectedMedicines.filter(
                                    (medicine) => medicine.id === item.id,
                                  ),
                                )}
                            </Row>
                          )}
                        </Draggable>
                      </Fragment>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-husband">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="droppable-husband"
                >
                  {selectedMedicines?.filter(
                    (item) => item.gynePatType === 'husband',
                  )?.length > 0 && <b>For Husband:</b>}
                  {organizeParentChild(selectedMedicines)
                    ?.filter((item) => item.gynePatType === 'husband')
                    .map((item, index) => (
                      <Fragment>
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Row
                              className="selected-medicine-row"
                              key={item.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getDragDropStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <Col
                                className={`medicine-schedule-parent `}
                                {...(!isHistoryPage
                                  ? {
                                      onClick: (e) =>
                                        setEditMedicineId(item?.id),
                                    }
                                  : {})}
                              >
                                <div
                                  className={`text-left ${
                                    item.parentId ? 'child-medicine' : ''
                                  }`}
                                >
                                  <span
                                    className={
                                      element?.showGeneric ? 'bold' : ''
                                    }
                                    style={itemSettingStyle(
                                      prescriptionItems?.items?.rx?.itemStyle ||
                                        {},
                                    )}
                                  >
                                    {item?.parentId ? item.sl : index + 1}.{' '}
                                    {MEDICINE_TYPES_SHORTS[item.type]}{' '}
                                    {item.name} {`${getRxStrength(item)}`}{' '}
                                    {getRxGenericName(item)}{' '}
                                    {item?.alterMedicine
                                      ? ` /  ${
                                          MEDICINE_TYPES_SHORTS[
                                            item?.alterMedicine?.type
                                          ]
                                        } ${
                                          item?.alterMedicine?.name
                                        } ${' '} ${getRxStrength(
                                          item?.alterMedicine,
                                        )}`
                                      : ''}{' '}
                                  </span>
                                </div>
                                {item?.itemDetails?.map((data, index) => {
                                  return (
                                    <SelectedMedicineSchedule
                                      data={data}
                                      item={item}
                                      typeName={item?.type}
                                      element={element}
                                      rxWhite={rxWhite}
                                      prescriptionItems={prescriptionItems}
                                      setEditMedicineId={setEditMedicineId}
                                    />
                                  );
                                })}
                              </Col>
                              <Col md={1}>
                                {!isHistoryPage && (
                                  <CommonDeleteBtn
                                    action={deleteMedicine}
                                    itemName={'rx'}
                                    delId={item.id}
                                  />
                                )}
                              </Col>
                              {editMedicineId === item.id &&
                                !isHistoryPage &&
                                selectedMedicineRow(
                                  selectedMedicines.filter(
                                    (medicine) => medicine.id === item.id,
                                  ),
                                )}
                            </Row>
                          )}
                        </Draggable>
                      </Fragment>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Fragment>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`droppable ${userInfo?.email === 'shahreerdr@gmail.com' ? 'shahreer-rx' : ''}`}
              >
                {organizeParentChild(selectedMedicines)?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Row
                        className="selected-medicine-row"
                        key={item.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getDragDropStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <Col
                          className={`medicine-schedule-parent `}
                          {...(!isHistoryPage
                            ? {
                                onClick: (e) => setEditMedicineId(item?.id),
                              }
                            : {})}
                        >
                          <div
                            className={`text-left ${
                              item.parentId ? 'child-medicine' : ''
                            }`}
                          >
                            <span
                              className={element?.showGeneric ? 'bold' : ''}
                              style={itemSettingStyle(
                                prescriptionItems?.items?.rx?.itemStyle || {},
                              )}
                            >
                              {item?.parentId ? item.sl : item.serial}.{' '}
                              {MEDICINE_TYPES_SHORTS[item.type]} {item.name}{' '}
                              {`${getRxStrength(item)}`}{' '}
                              {getRxGenericName(item)}{' '}
                              {item?.alterMedicine
                                ? ` /  ${
                                    MEDICINE_TYPES_SHORTS[
                                      item?.alterMedicine?.type
                                    ]
                                  } ${
                                    item?.alterMedicine?.name
                                  } ${' '} ${getRxStrength(
                                    item?.alterMedicine,
                                  )}`
                                : ''}{' '}
                            </span>
                          </div>
                          {item?.itemDetails?.map((data, index) => {
                            return (
                              <SelectedMedicineSchedule
                                data={data}
                                item={item}
                                typeName={item?.type}
                                element={element}
                                rxWhite={rxWhite}
                                prescriptionItems={prescriptionItems}
                                setEditMedicineId={setEditMedicineId}
                              />
                            );
                          })}
                        </Col>
                        <Col md={1}>
                          {!isHistoryPage && (
                            <CommonDeleteBtn
                              action={deleteMedicine}
                              itemName={'rx'}
                              delId={item.id}
                            />
                          )}
                        </Col>
                        {editMedicineId === item.id &&
                          !isHistoryPage &&
                          selectedMedicineRow(
                            selectedMedicines.filter(
                              (medicine) => medicine.id === item.id,
                            ),
                          )}
                      </Row>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default memo(RxView);
