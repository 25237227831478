// import "./styles.css";
import React from 'react';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const barColors = [
  '#2196f3',
  '#00bcd4',
  '#cddc39',
  '#8bc34a',
  '#ff9800',
  '#795548',
  '#607d8b',
];

export default function ReactBarChart({ chartData, percent = 0 }) {
  function CustomTooltip({ active, payload, label }) {
    if (active && payload) {
      return (
        <div
          style={{
            backgroundColor: '#f1c40fa1',
            padding: 10,
            color: 'black',
          }}
        >
          <span style={{ display: 'block' }}>{`${label}`}</span>
          <span>
            {percent ? 'Percentage' : 'Count'}:{' '}
            {`${payload[0].value}${percent ? '%' : ''}`}
          </span>
        </div>
      );
    }

    return null;
  }

   // Custom Y-axis Tick Renderer
   const renderCustomTick = ({ x, y, payload }) => {
    return (
      <text
        x={x}
        y={y}
        dy={6}
        textAnchor="end"
        fill="black"
        style={{ whiteSpace: 'nowrap', fontSize: '14px' }}
      >
        {payload.value}
      </text>
    );
  };

  return (
    <ComposedChart
      layout="vertical"
      width={700}
      height={40 * (chartData?.length + 2)}
      data={chartData}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 200,
      }}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis type="number" />
      <YAxis
        dataKey="name"
        type="category"
        scale="band"
        tick={{ fontSize: 12, fill: 'black' }}
        tick={renderCustomTick}
      />
      <Tooltip content={CustomTooltip} wrapperStyle={{ outline: 'none' }} />
      <Bar dataKey="count" barSize={30} fill="#413ea0">
        {chartData?.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={barColors[index % barColors.length]}
          />
        ))}
      </Bar>
    </ComposedChart>
  );
}
