import { Fragment, memo } from 'react';
import angiogramImg from '../../../../assets/images/angiogram.jpg';
import coronaryImg from '../../../../assets/images/coronary.jpg';
import {
  capitalizeFirstLetter,
  clearSystemicData,
  styledComponent,
} from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';

const SystemicExamination = ({
  selectedOnExamination,
  setSelectedOnExamination,
  prescriptionItems,
  isDelBtn,
  isHistoryPage,
}) => {
  let { systemicExamination } = selectedOnExamination || {};
  let { jvp, lph, palpableP2, heartSound, murmur, lungBase } =
    systemicExamination || {};

  const deleteData = (itemName) => {
    selectedOnExamination.systemicExamination = clearSystemicData(
      systemicExamination,
      itemName,
    );
    setSelectedOnExamination({ ...selectedOnExamination });
  };

  const Title = styledComponent(
    prescriptionItems?.items?.['on-examination']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['on-examination']?.subItemStyle || {},
  );

  return (
    <div className="systemic-examination-view">
      {(jvp?.status ||
        jvp?.note ||
        systemicExamination?.apexBeatNote ||
        lph?.status ||
        lph?.note ||
        palpableP2?.status ||
        heartSound?.s1 ||
        heartSound?.s2 ||
        heartSound?.note ||
        murmur?.systolic ||
        murmur?.diastolic ||
        murmur?.note ||
        lungBase?.raised ||
        lungBase?.crepitation ||
        lungBase?.rhonchi ||
        lungBase?.normal) && <div className="his-sub-title">CVS</div>}
      <ul>
        {(jvp?.status || jvp?.note) && (
          <li>
            <span className="text-left">
              <Title>JVP:</Title>{' '}
              <Value>{jvp?.status && capitalizeFirstLetter(jvp?.status)}</Value>
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'jvp'}
                isDelBtn={isDelBtn}
              />
            )}
            <Value>
              {jvp?.status && jvp?.note && (
                <div className="pl-3">{jvp.note ? `- ${jvp.note}` : ''}</div>
              )}
              {jvp?.note && !jvp?.status && <span>{jvp.note}</span>}
            </Value>
          </li>
        )}
        {systemicExamination?.apexBeatNote && (
          <li>
            <span className="text-left pr-5">
              <Title>Apex Beat:</Title>
              <Value> {systemicExamination?.apexBeatNote}</Value>
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'apexBeatNote'}
                isDelBtn={isDelBtn}
              />
            )}
          </li>
        )}
        {(lph?.status || lph?.note) && (
          <li>
            <span className="text-left">
              <Title>LPH:</Title>
              <Value>
                {' '}
                {lph?.status && capitalizeFirstLetter(lph?.status)}
              </Value>
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'lph'}
                isDelBtn={isDelBtn}
              />
            )}
            {/* <Value>
              {lph?.note && (
                <div className="pl-2 font-italic">
                  {lph.note ? `- ${lph.note}` : ''}
                </div>
              )}
            </Value> */}
            <Value>
              {lph?.status && lph?.note && (
                <div className="pl-3">{lph.note ? `- ${lph.note}` : ''}</div>
              )}
              {lph?.note && !lph?.status && <span>{lph.note}</span>}
            </Value>
          </li>
        )}
        {(palpableP2?.status || palpableP2?.note) && (
          <li>
            <span className="text-left">
              <Title>Palpable P2:</Title>
              <Value>
                {' '}
                {palpableP2?.status &&
                  capitalizeFirstLetter(palpableP2?.status)}
              </Value>
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'palpableP2'}
                isDelBtn={isDelBtn}
              />
            )}
            {/* <Value>
              {palpableP2?.note && (
                <div className="pl-2 font-italic">
                  {palpableP2.note ? `- ${palpableP2.note}` : ''}
                </div>
              )}
            </Value> */}
            <Value>
              {palpableP2?.status && palpableP2?.note && (
                <div className="pl-3">
                  {palpableP2.note ? `- ${palpableP2.note}` : ''}
                </div>
              )}
              {palpableP2?.note && !palpableP2?.status && (
                <span>{palpableP2.note}</span>
              )}
            </Value>
          </li>
        )}
        {(heartSound?.s1 || heartSound?.s2 || heartSound?.note) && (
          <li>
            <span className="text-left">
              {heartSound?.s1 && (
                <Fragment>
                  <Title>Heart Sound: S1:</Title>
                  <Value> {heartSound?.s1}</Value>
                </Fragment>
              )}
              {heartSound?.s2 && (
                <Fragment>
                  <Title> S2:</Title>
                  <Value> {heartSound?.s2}</Value>
                </Fragment>
              )}
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'heartSound'}
                isDelBtn={isDelBtn}
              />
            )}
            <Value>
              {/* {heartSound?.note && (
                <div className="pl-2 font-italic">- {heartSound?.note}</div>
              )} */}
              {(heartSound?.s1 || heartSound?.s2) && heartSound?.note && (
                <div className="pl-3">
                  {heartSound.note ? `- ${heartSound.note}` : ''}
                </div>
              )}
              {heartSound?.note && !heartSound?.s1 && !heartSound?.s2 && (
                <span>Heart Sound: {heartSound.note}</span>
              )}
            </Value>
          </li>
        )}
        {(murmur?.systolic || murmur?.diastolic || murmur?.note) && (
          <li>
            <span className="text-left pr-3">
              <Title>Murmur:</Title>
              {murmur?.systolic && <Value> Systolic</Value>}
              {murmur?.diastolic && <Value> Diastolic</Value>}
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'murmur'}
                isDelBtn={isDelBtn}
              />
            )}
            <Value>
              {(murmur?.systolic || murmur?.diastolic) && murmur?.note && (
                <div className="pl-3">
                  {murmur.note ? `- ${murmur.note}` : ''}
                </div>
              )}
              {murmur?.note && !murmur?.systolic && !murmur?.diastolic && (
                <span>{murmur.note}</span>
              )}
            </Value>
          </li>
        )}
        {(lungBase?.raised ||
          lungBase?.crepitation ||
          lungBase?.rhonchi ||
          lungBase?.normal ||
          lungBase?.note) && (
          <li>
            <span className="text-left pr-3">
              <Title>Lung Base:</Title>{' '}
              {lungBase?.raised && <Value>Raised</Value>}
              {lungBase?.crepitation && <Value>Crepitation</Value>}
              {lungBase?.rhonchi && <Value>Rhonchi</Value>}
              {lungBase?.normal && <Value>Normal</Value>}
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'lungBase'}
                isDelBtn={isDelBtn}
              />
            )}
            <Value>
              {(lungBase?.raised ||
                lungBase?.crepitation ||
                lungBase?.rhonchi ||
                lungBase?.normal) &&
                lungBase?.note && (
                  <div className="pl-2 font-italic">
                    {lungBase.note ? `- ${lungBase.note}` : ''}
                  </div>
                )}
              {!lungBase?.raised &&
                !lungBase?.crepitation &&
                !lungBase?.rhonchi &&
                !lungBase?.normal &&
                lungBase?.note && <span>{lungBase.note}</span>}
            </Value>
          </li>
        )}
      </ul>
      {systemicExamination?.rsNote && <div className="his-sub-title">R/S</div>}
      <ul>
        {systemicExamination?.rsNote && (
          <li>
            <span className="text-left pr-5">
              {/* <Title>R/S:</Title> */}
              <Value> {systemicExamination?.rsNote}</Value>
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'rsNote'}
                isDelBtn={isDelBtn}
              />
            )}
          </li>
        )}
      </ul>
      {systemicExamination?.gsNote && <div className="his-sub-title">G/S</div>}
      <ul>
        {systemicExamination?.gsNote && (
          <li>
            <span className="text-left pr-5">
              {/* <Title>G/S:</Title> */}
              <Value> {systemicExamination?.gsNote}</Value>
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'gsNote'}
                isDelBtn={isDelBtn}
              />
            )}
          </li>
        )}
      </ul>
      {(systemicExamination?.cnsNote ||
        systemicExamination?.showCoronary ||
        systemicExamination?.showAngiogram) && (
        <div className="his-sub-title">CNS</div>
      )}
      <ul>
        {systemicExamination?.cnsNote && (
          <li>
            <span className="text-left pr-5">
              {/* <Title>CNS:</Title> */}
              <Value> {systemicExamination?.cnsNote}</Value>
            </span>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={deleteData}
                itemName={'cnsNote'}
                isDelBtn={isDelBtn}
              />
            )}
          </li>
        )}
        {systemicExamination?.showCoronary && (
          <li>
            <div>
              <Title>Coronary Artery:</Title>
            </div>
            <div className="coronary">
              <img src={coronaryImg} height={200} width={320} />
              {!isHistoryPage && (
                <CommonDeleteBtn
                  action={deleteData}
                  itemName={'showCoronary'}
                  isDelBtn={isDelBtn}
                />
              )}
            </div>
          </li>
        )}
        {systemicExamination?.showAngiogram && (
          <li>
            <div>
              <Title>Angiogram: </Title>
            </div>
            <div className="angiogram">
              <img src={angiogramImg} height={250} width={320} />
              {!isHistoryPage && (
                <CommonDeleteBtn
                  action={deleteData}
                  itemName={'showAngiogram'}
                  isDelBtn={isDelBtn}
                />
              )}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default memo(SystemicExamination);
