import {
  agePrint,
  separateDateTime,
  capitalizeFirstLetter,
  getPatientIdForDoctor,
} from '../../../utils/helpers';
import { Row, Col } from 'react-bootstrap';
import profileImg from '../../../assets/images/profile.svg';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetRequest } from '../../../hooks/useGetRequest';

const PatientHistoryLeft = ({
  patientData,
  prescriptions,
  generalExamination,
}) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const [imageContent, setImageContent] = useState('');
  const patientSettings = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.patientSettings,
  );
  const ageProperties = patientSettings?.items?.find(
    (item) => item.name === 'age',
  );
  let { unitProperties } = ageProperties || {};

  const countTotalItems = () => {
    return prescriptions?.reduce(
      (total, data) => {
        total.chiefComplain += data?.chiefComplains?.length || 0;
        total.diagnosis += data?.diagnoses?.length || 0;
        total.investigation += data?.investigations?.length || 0;
        total.advice += data?.advices?.length || 0;
        total.medicine += data?.medicines?.length || 0;
        total.followUp += data?.followUps?.length || 0;
        return total;
      },
      {
        chiefComplain: 0,
        diagnosis: 0,
        investigation: 0,
        advice: 0,
        medicine: 0,
        followUp: 0,
      },
    );
  };

  const { isLoading: isGetImageLoading, refetch: getImageLoad } = useGetRequest(
    `getSignatureContent-${patientData.id}`,
    `auth-patient/patient-profile-picture-for-doctor/${patientData.id}`,
    (data) => {
      setImageContent(data);
    },
    (e) => {
      console.log(e);
    },
  );

  useEffect(() => {
    countTotalItems();
    getImageLoad();
  }, []);

  return (
    <>
      <div className="text-center">
        {imageContent === '' || isGetImageLoading ? (
          <img
            className="patient-img rounded-circle"
            src={profileImg}
            alt="profile image"
          />
        ) : (
          <img
            src={imageContent}
            className="patient-img rounded-circle"
            alt="profile image"
          />
        )}
        <div className="patient-name">{patientData?.name}</div>
        <div className="patient-phone-number">{patientData?.phoneNumber}</div>
        <div className="patient-email">{patientData?.email}</div>
        <div className="patient-pid">
          Patient ID:{' '}
          {getPatientIdForDoctor(patientData?.patientIdForDoctor, userInfo)}
        </div>
      </div>
      <hr className="mb-0" />
      <Row>
        <Col className="m-2">
          <Row className="summary-box">
            <Col>
              <div className="pt-1">Gender</div>
              <div className="pt-1 font-weight-bold">
                {capitalizeFirstLetter(patientData?.gender)}
              </div>
            </Col>
            <Col>
              <div className="pt-1">Age</div>
              <div className="pt-1 font-weight-bold">
                {agePrint(separateDateTime(patientData?.dob), unitProperties)}
              </div>
            </Col>
          </Row>
          <Row className="summary-box">
            <Col>
              <div className="pt-1">Height</div>
              <div className="pt-1 font-weight-bold">
                {generalExamination?.heightInfo?.feet}
                {generalExamination?.heightInfo?.inch
                  ? '.' + generalExamination?.heightInfo?.inch
                  : ''}{' '}
                {generalExamination?.heightInfo?.heightUnit}
              </div>
            </Col>
            <Col>
              <div className="pt-1">Weight</div>
              <div className="pt-1 font-weight-bold">
                {generalExamination?.weightInfo?.weight}{' '}
                {generalExamination?.weightInfo?.weightUnit}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-1 summary-box">
        <Col>
          <div className="pt-1">Total Appointments</div>
          <div style={{ fontSize: '24px' }}>{prescriptions?.length}</div>
        </Col>
      </Row>
      <Row>
        <Col className="ml-1 mr-1 mb-1">
          <div className="text-center font-weight-bold">
            Summary of prescriptions
          </div>
          <Row className="summary-box">
            <Col>
              <div className="value">{countTotalItems()?.chiefComplain}</div>
              <div className="item">Chief Complain</div>
            </Col>
            <Col>
              <div className="value">{countTotalItems()?.diagnosis}</div>
              <div className="item">Diagnosis</div>
            </Col>
            <Col>
              <div className="value">{countTotalItems()?.medicine}</div>
              <div className="item">Rx</div>
            </Col>
          </Row>
          <Row className="summary-box">
            <Col>
              <div className="value">{countTotalItems()?.followUp}</div>
              <div className="item">Follow Up</div>
            </Col>
            <Col>
              <div className="value">{countTotalItems()?.investigation}</div>
              <div className="item">Investigation</div>
            </Col>
            <Col>
              <div className="value">{countTotalItems()?.advice}</div>
              <div className="item">Advice</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PatientHistoryLeft;
