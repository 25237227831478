import React, { memo, useRef, useState } from 'react';
import { Button, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import profileImg from '../../../assets/images/profile.svg';
import { deleteRequest } from '../../../utils/axiosRequests';
import {
  agePrint,
  capitalizeFirstLetter,
  separateDateTime,
} from '../../../utils/helpers';
import LoadingButton from '../../forms/LoadingButton';
import BasicPatientInfo from '../partials/basicPatientInfo';
import MedicalPatientInfo from '../partials/medicalPatientInfo';
import OthersPatientInfo from '../partials/othersPatientInfo';
import WebcamCapture from './WebcamCapture';

const AddPatientInfo = ({
  patientPicture,
  patientInfo,
  setPatientInfo,
  infoModal,
  setInfoModal,
  selectedOnExamination,
  setSelectedOnExamination,
  appointmentInfo,
  setAppointmentInfo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCamera, setIsOpenCamera] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [errors, setErrors] = useState({});

  const patientSettings = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.patientSettings,
  );
  const ageProperties = patientSettings?.items?.find(
    (item) => item.name === 'age',
  );
  let { unitProperties } = ageProperties || {};

  const ref = useRef(null);
  useClickAway(ref, () => {
    setInfoModal(false);
  });

  const deletePatientImage = () => {
    if (patientPicture) {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this image?',
      );
      // Proceed with deletion if confirmed
      if (confirmDelete) {
        deleteRequest(`auth/patient-img-delete/${patientInfo.id}`)
          .then((res) => {
            console.log(res, 'deletePatientImage');
          })
          .catch((error) => {});
      }
    } else {
      setImageSrc(null);
    }
  };

  return (
    <Modal show={infoModal} size="lg">
      <Modal.Header className="common-modal-header">
        <Col lg={10} md={10} sm={10} xs={10}>
          <Modal.Title>Patient Info</Modal.Title>
        </Col>
        <Col lg={1} md={1} sm={1} xs={1} style={{ textAlign: 'end' }}>
          <i
            onClick={() => {
              setInfoModal(false);
            }}
            className="fa fa-times-circle cursor-pointer"
            aria-hidden="true"
          ></i>
        </Col>
      </Modal.Header>
      <Modal.Body ref={ref}>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card shadow">
              <div className="card-body patient-history patient-info">
                <Row>
                  <Col
                    md={3}
                    className="patient-details pt-3"
                    style={{ fontSize: '15px' }}
                  >
                    <div className="icon-list">
                      <i
                        className="fa fa-plus-circle"
                        onClick={() => setIsOpenCamera(true)}
                      ></i>
                      <i
                        className="fa fa-edit"
                        onClick={() => setIsOpenCamera(true)}
                      ></i>
                      {(patientPicture || imageSrc) && (
                        <i
                          className="fa fa-times-circle cursor-pointer"
                          onClick={() => deletePatientImage()}
                        ></i>
                      )}
                    </div>
                    <div className="patient-img-info">
                      {!isOpenCamera ? (
                        <img
                          className="profile-img"
                          src={imageSrc || patientPicture || profileImg}
                        />
                      ) : (
                        <WebcamCapture
                          imageSrc={imageSrc}
                          setImageSrc={setImageSrc}
                          isOpenCamera={isOpenCamera}
                          patientInfo={patientInfo}
                          setPatientInfo={setPatientInfo}
                          setIsOpenCamera={setIsOpenCamera}
                        />
                      )}
                      <div className="font-weight-bold mt-2">
                        {patientInfo?.name}
                      </div>
                      <div>{patientInfo?.phoneNumber}</div>
                      <div>{patientInfo?.email}</div>
                      {/* { patientInfo?.id && <div>Patient ID: <b>{patientInfo?.id}</b></div>
                          <div className="mt-2">
                          <i className="fas fa-phone-alt mr-4" aria-hidden="true"></i>
                          <i className="fas fa-video mr-4" aria-hidden="true"></i>
                          <i className="fas fa-comment-alt" aria-hidden="true"></i>
                      </div> */}
                    </div>
                    <hr />
                    <Row className="text-center">
                      <Col>
                        <Row>
                          <Col>
                            <div>Gender</div>
                            <div className="font-weight-bold mt-2">
                              {capitalizeFirstLetter(patientInfo?.gender)}
                            </div>
                            <hr />
                          </Col>
                          <Col>
                            <div>Age</div>
                            <div className="font-weight-bold mt-2">
                              {/* {isNaN(ageCount(patientInfo?.dob))
                                ? ageCount(patientInfo?.dob)
                                : ''} */}
                              {agePrint(
                                separateDateTime(patientInfo?.dob),
                                unitProperties,
                              )}
                              {agePrint()} &nbsp;
                            </div>
                            <hr />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div>Height</div>
                            <div className="font-weight-bold mt-2">
                              {
                                selectedOnExamination?.generalExaminations
                                  ?.heightInfo?.feet
                              }
                              {selectedOnExamination?.generalExaminations
                                ?.heightInfo?.inch
                                ? '.' +
                                  selectedOnExamination.generalExaminations
                                    ?.heightInfo?.inch
                                : ''}
                              {selectedOnExamination?.generalExaminations
                                ?.heightInfo?.feet ||
                              selectedOnExamination.generalExaminations
                                ?.heightInfo?.inch
                                ? ' ' +
                                  selectedOnExamination.generalExaminations
                                    ?.heightInfo?.heightUnit
                                : ''}
                            </div>
                            <hr />
                          </Col>
                          <Col>
                            <div>Weight</div>
                            <div className="font-weight-bold mt-2">
                              {selectedOnExamination?.generalExaminations
                                ?.weightInfo?.weight
                                ? selectedOnExamination.generalExaminations
                                    ?.weightInfo?.weight +
                                  ' ' +
                                  selectedOnExamination.generalExaminations
                                    ?.weightInfo?.weightUnit
                                : ''}
                            </div>
                            <hr />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={9} className="pl-4">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="basic"
                    >
                      <Nav
                        variant="pills"
                        className="d-flex custom-tab"
                        style={{ alignItems: 'center', textAlign: 'center' }}
                      >
                        <Nav.Item className="flex-fill">
                          <Nav.Link eventKey="basic">
                            Basic Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="flex-fill">
                          <Nav.Link eventKey="medical">
                            Medical Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="flex-fill">
                          <Nav.Link eventKey="others">Others</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="basic">
                          <BasicPatientInfo
                            patientInfo={patientInfo}
                            setPatientInfo={setPatientInfo}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="medical">
                          <MedicalPatientInfo
                            patientInfo={patientInfo}
                            setPatientInfo={setPatientInfo}
                            selectedOnExamination={selectedOnExamination}
                            setSelectedOnExamination={setSelectedOnExamination}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="others">
                          <OthersPatientInfo
                            patientInfo={patientInfo}
                            setPatientInfo={setPatientInfo}
                            appointmentInfo={appointmentInfo}
                            setAppointmentInfo={setAppointmentInfo}
                          />
                        </Tab.Pane>
                        <Row className="mt-2 mb-2">
                          <Col className="text-center">
                            {isLoading ? (
                              <LoadingButton btnFull="yes" />
                            ) : (
                              <Button
                                variant="primary"
                                size="sm"
                                style={{ width: 'inherit' }}
                                onClick={() => setInfoModal(false)}
                              >
                                <b>Done</b>
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default memo(AddPatientInfo);
