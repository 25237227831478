import { memo } from 'react';

const DurationArrowIcon = ({ currentVal, setDuration, className }) => {
  return (
    <span className={`duration-arrow ${className}`}>
      <span
        class="icon icon-up"
        onClick={(e) => {
          e.stopPropagation();
          setDuration(currentVal ? currentVal + 1 : 1);
        }}
      ></span>
      <span
        class="icon icon-down"
        onClick={(e) => {
          e.stopPropagation();
          setDuration(currentVal ? currentVal - 1 : 0);
        }}
      ></span>
    </span>
  );
};

export default memo(DurationArrowIcon);
