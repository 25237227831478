import React, { memo, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useGetRequest } from '../../../hooks/useGetRequest';
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '../../../utils/axiosRequests';
import { englishToBanglaNumber } from '../../../utils/helpers';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchAreaAsync';

const FollowUpModal = ({
  modalTitle,
  selectedFollowUps,
  setSelectedFollowUps,
  showFollowUp,
  setShowFollowUp,
}) => {
  const [followUps, setFollowUps] = useState([]);
  const [followUpsInSearch, setFollowUpsInSearch] = useState([]);
  const [comeAfter, setComeAfter] = useState(0);
  const [timeUnit, setTimeUnit] = useState('দিন');
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { isLoading: isFollowupLoading, refetch: getFollowups } = useGetRequest(
    'getFollowupList',
    `followups`,
    (res) => {
      setFollowUps(res?.data);
    },
    (e) => {
      console.log(e);
    },
  );

  const selectFollowUps = (item) => {
    if (selectedFollowUps?.some((follow) => follow === item.name)) {
      setSelectedFollowUps(selectedFollowUps?.filter((f) => f !== item.name));
    } else {
      setSelectedFollowUps([...selectedFollowUps, item.name]);
    }
  };

  const deleteFollowUp = async (followupId) => {
    await deleteRequest(`followups/${followupId}`)
      .then((data) => {
        setFollowUps(
          followUps?.filter((followup) => followup.id !== followupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectedFollowUpList = () => {
    return selectedFollowUps?.map((item, index) => {
      return (
        <Row className="selected-item-row" key={index}>
          <Col lg={11} md={11} sm={11} xs={10}>
            {item}
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className="text-right">
            <i
              className="fa fa-times-circle"
              aria-hidden="true"
              onClick={() => removeFollowUp(item)}
            ></i>
          </Col>
        </Row>
      );
    });
  };

  let allFollowUp = followUps?.map((item, index) => {
    let isSelected = selectedFollowUps?.some((data) => data === item.name);

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectFollowUps}
        removeClickAction={deleteFollowUp}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const { name: selectedData, customOption: isNewOption } =
        selectedOption[0];
      if (isNewOption) {
        const data = await postRequest('followups', {
          name: selectedData,
        });
        setFollowUps([...followUps, data]);
        setSearchQuery('');
        setSelectedFollowUps([...selectedFollowUps, selectedData]);
      } else {
        const isExisting = selectedFollowUps?.some(
          ({ name }) => name?.toLowerCase() === selectedData?.toLowerCase(),
        );
        setSelectedFollowUps(
          isExisting
            ? selectedFollowUps?.filter(
                ({ name }) =>
                  name?.toLowerCase() !== selectedData?.toLowerCase(),
              )
            : [...selectedFollowUps, selectedData],
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFollowInput = (fieldName, e) => {
    const val = e.target.value;
    if (fieldName === 'comeDate') {
      selectedFollowUps[0] = englishToBanglaNumber(val) + ' তারিখে দেখা করবেন';
      setSelectedFollowUps([...selectedFollowUps]);
    } else if (fieldName === 'comeAfter') {
      selectedFollowUps[0] =
        englishToBanglaNumber(val) + ' ' + timeUnit + ' পর দেখা করবেন';
      setSelectedFollowUps([...selectedFollowUps]);
    } else if (fieldName === 'unit') {
      selectedFollowUps[0] =
        englishToBanglaNumber(comeAfter) + ' ' + val + ' পর দেখা করবেন';
      setSelectedFollowUps([...selectedFollowUps]);
    }
  };

  const removeFollowUp = (item) => {
    setSelectedFollowUps(
      selectedFollowUps.filter((selectedItem) => selectedItem !== item),
    );
  };

  const handleOnInputChange = async (searchKey) => {
    if (!searchKey) return;
    setIsLoading(true);
    setFollowUpsInSearch([]);
    setSearchQuery(searchKey);

    try {
      const { data } = await getRequest(
        `followups?name=${encodeURIComponent(searchKey)}`,
      );
      setFollowUpsInSearch(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showFollowUp) {
      getFollowups();
    } else {
      setSearchQuery('');
      setFollowUpsInSearch([]);
    }
  }, [showFollowUp]);

  return (
    <Modal
      show={showFollowUp}
      size="lg"
      className="customize-modal-size"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* <ModalHeader title={modalTitle} action={setShowFollowUp} /> */}
      <Modal.Body>
        <SearchArea
          modalTitle={modalTitle}
          closeAction={setShowFollowUp}
          setSearchOptions={setFollowUpsInSearch}
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          options={followUpsInSearch}
          placeholder={'follow up'}
          selectedInputs={selectedFollowUps}
        />
        <hr />
        <Row className="follow-up-form">
          <Col lg={7} md={7} sm={12} xs={12} className="responsive">
            <Form.Group as={Row}>
              <Form.Label
                column
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="text-center pr-0"
              >
                পরবর্তী ভিজিট:
              </Form.Label>
              <Col lg={5} md={5} sm={5} xs={5} className="pl-0">
                <Form.Control
                  className="form-control form-control-sm"
                  type="number"
                  min={0}
                  defaultValue={0}
                  onChange={(e) => {
                    setComeAfter(e.target.value);
                    handleFollowInput('comeAfter', e);
                  }}
                />
              </Col>
              <Col lg={3} md={3} sm={3} xs={3} className="pl-0">
                <Form.Select
                  className="form-control form-control-sm"
                  onChange={(evt) => {
                    setTimeUnit(evt.target.value);
                    handleFollowInput('unit', evt);
                  }}
                >
                  <option value={'দিন'}>দিন</option>
                  <option value="সপ্তাহ">সপ্তাহ</option>
                  <option value={'মাস'}>মাস</option>
                  <option value={'বছর'}>বছর</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col lg={5} md={5} sm={12} xs={12}>
            <Form.Group as={Row}>
              <Form.Label
                column
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="text-right pr-0"
              >
                Add Date:
              </Form.Label>
              <Col lg={8} md={8} sm={8} xs={8} className="pl-0">
                <Form.Control
                  type="date"
                  className="form-control form-control-sm"
                  onChange={(evt) => handleFollowInput('comeDate', evt)}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <hr className="custom-hr" />
        <div
          className="add-scroll"
          style={{ height: '211px', background: '#F5F5F5' }}
        >
          <Row className="complains-area" style={{ margin: 0 }}>
            {allFollowUp}
          </Row>
        </div>
        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">{selectedFollowUpList()}</div>
      </Modal.Body>
    </Modal>
  );
};
export default memo(FollowUpModal);
